import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Menu, Container } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Button, Popup,  Table, Icon, Label } from 'semantic-ui-react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';


import { moneyFormat } from '../Shared/Utils/Money';

import { fetchArticlesPreview } from '../../api/articles';
import { setActiveArticle } from '../../actions/articles';
import { getArticlesPreview } from '../../reducers/articles';
import { getSelectedCurrency, getProductsSum, getReadableCurrency, getCartProducts } from '../../reducers/cart';
import { goTo } from '../../actions/router';
import { getRoutes, getRoute } from '../../reducers/router';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import logo from '../../resources/images/navbar-logo.png';
import logogreen from '../../resources/images/navbar-logo-green.png';

import '../App.css'

import DesktopMenu  from './desktop-menu';
import { FullscreenMenu } from './MobileMenu';

function NavBar(props) {
  const { products, currency, totalSum, selectedCurrency, articlesPreview, menu, goTo, route } = props;

  const [open, setOpen] = useState(false)
  
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const mobile = useMediaQuery('(max-width:800px)');

  const urlRoute = props.history.location.pathname;

  useEffect(() => {
    fetchArticlesPreview();
    
    goTo(urlRoute)
  }, [])

  const fetchArticlesPreview = () => props.fetchArticlesPreview();

  return (
    
    <div className="NavBar">
      <Container>
        <Link to="/domu">
          <div className='logo-wrapper'>
            <img src={logo} className='navbar-logo' />
            <img src={logogreen} className='navbar-logo-green' />
          </div>
        </Link>

        {!mobile ? (
          <DesktopMenu 
            route={route}
            menu={menu}
            articlesPreview={articlesPreview}
            totalSum={totalSum}
            currency={currency}
            moneyFormat={moneyFormat}
            products={products}
            selectedCurrency={selectedCurrency}
          />
      ) : (
        <div style={{float: 'right'}}>
          <FullscreenMenu articlesPreview={articlesPreview} />
        </div>
      )}
      
      </Container>
    </div>
  )
}


const mapStateToProps = state => ({
  products: getCartProducts(state),
  totalSum: getProductsSum(state),
  selectedCurrency: getSelectedCurrency(state),
  currency: getReadableCurrency(state),
  articlesPreview: getArticlesPreview(state),
  menu: getRoutes(state),
  route: getRoute(state)
})

export default connect(mapStateToProps, { setActiveArticle, fetchArticlesPreview, goTo })(withRouter(NavBar));
