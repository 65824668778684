import React from 'react'
import { Container, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import '../Pages/Pages.css';

import footerlogo from '../../resources/images/footer-logo.png';

function Footer() {
  return (
    <div className="Footer">
      <Container>
        <Grid className="info-box">
          <Grid.Row columns={2}>
            <Grid.Column>
              <h3>Didaktické fototapety</h3>
              <ul>
                <li>
                <Link to="/produkty">
                  Produkty
                </Link>
              </li>
              
              <li>
              <Link to="/obchodni-podminky">
                Obchodní podmínky
              </Link>
              </li>
              
              <li>
                <Link to="/gdpr">
                  GDPR
                </Link>
              </li>
              <li>
                <Link to="/cookies">
                  Cookies
                </Link>
              </li>
              </ul>
            </Grid.Column>
            <Grid.Column>
              <Link to="/domu">
                <img src={footerlogo} className='footer-logo' />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

  export default Footer;